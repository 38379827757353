



















































































































































































































import Vue from 'vue';
import WidgetSearchClassRequestViewModel from '../../../../services/v1/viewModel/resource/WidgetSearchEventRequestViewModel';
import ApiPagingRequestViewModel from '../../../../services/v1/viewModel/resource/ApiPagingRequestViewModel';
import WidgetClassListResourceViewModel from '../../../../services/v1/viewModel/resource/WidgetEventListResourceViewModel';
import ContactAddressResourceViewModel from '../../../../services/v1/viewModel/resource/ContactAddressResourceViewModel';
import RegisterClassRequestViewModel from '../../../../services/v1/viewModel/resource/RegisterEventRequestViewModel';
import Int64LookupResourceViewModel from '../../../../services/v1/viewModel/resource/Int64LookupResourceViewModel';

export default Vue.extend({
  layout: 'layout-widget',
  data: () => ({
    isRegisterSuccess: false,
    isSending: false,
    isLoading: true,
    specificQuestionsFormDefinition: undefined as any,
    institutionModel: new ContactAddressResourceViewModel(),
    model: new RegisterClassRequestViewModel().fromDTO({
      contactAddress: {},
      correspondenceAddress: { contactAddress: { }, isDifferent: false },
      invoiceAddress: { contactAddress: { }, isDifferent: false },
      registrationAnswers: { },
    } as any),
    eventId: undefined as undefined | number,
    course: new WidgetClassListResourceViewModel(),
    responseError: undefined as any,
    countries: [] as Int64LookupResourceViewModel[],
    search: undefined as string | undefined,
  }),
  watch: {
    'model.correspondenceAddress.isDifferent': {
      handler() {
        if (!this.model.correspondenceAddress.isDifferent) {
          this.model.correspondenceAddress.contactAddress = new ContactAddressResourceViewModel();
        } else {
          this.updateFromInstitutionModel();
        }
      },
    },
    'model.invoiceAddress.isDifferent': {
      handler() {
        if (!this.model.invoiceAddress.isDifferent) {
          this.model.invoiceAddress.contactAddress = new ContactAddressResourceViewModel();
        } else {
          this.updateFromInstitutionModel();
        }
      },
    },
    institutionModel: {
      deep: true,
      handler() {
        this.updateFromInstitutionModel();
      },
    },
  },
  async mounted() {
    const pp = this.$routerUtils.getQueryParams();
    const eventid = this.$config.values['widget-eventid'] ?? this.$route.params.eventid ?? pp.eventid;
    if (this._.isSet(eventid)) {
      this.eventId = Number.parseInt(eventid, 10);
      if (!Number.isNaN(this.eventId)) {
        await this.loadEventInformation();
      } else {
        this.responseError = `${this.$t('widget.events.invalidEventNumber')}: ${pp.eventid}`;
      }
    } else {
      this.responseError = this.$t('widget.events.noEventNumberSpecified');
      this.$log.error('No eventId given');
    }
  },
  methods: {
    updateFromInstitutionModel() {
      this.institutionModel.isCompany = true;
      if (!this.institutionModel.countryId) {
        this.institutionModel.countryId = this.countries.find((x) => x.details === 'CH')?.id;
      }
      if (this.model.correspondenceAddress.isDifferent) {
        this.model.correspondenceAddress.contactAddress = this.institutionModel;
      }
      if (this.model.invoiceAddress.isDifferent) {
        this.model.invoiceAddress.contactAddress = this.institutionModel;
      }
    },
    async sendForm() {
      this.model.eventId = this.eventId!;
      if (this.model.correspondenceAddress.isDifferent || this.model.invoiceAddress.isDifferent) {
        // Needs to be validated separately since it's not the same model, but schemas are kept in same place
        if (!this.institutionModel.validateWithSchema(this.model.schemaInstitution)) {
          return;
        }
      }
      const validateResultMode = this.model.validate();
      const validateResultRegiAnswers = this.model.registrationAnswers.validate();
      if (!validateResultMode || !validateResultRegiAnswers) {
        return;
      }
      this.isSending = true;
      try {
        this.model.languageIso2 = this.$translation.get()?.toLowerCase();
        this.model.contactAddress.countryIso2 = this.countries.find((x) => x.id === this.model.contactAddress.countryId)?.details;
        if (this.institutionModel.countryId) {
          this.institutionModel.countryIso2 = this.countries.find((x) => x.id === this.institutionModel.countryId)?.details;
        }
        await this.$service.v1.api.widgetEvents.widgetRegisterForEvent(this.eventId!, this.model);
      } catch (e: any) {
        this.$log.error(e);
        this.responseError = e;
      }
      this.isRegisterSuccess = true;
      this.isSending = false;
    },
    async loadDynamicForms() {
      const responseSpecific = await this.$service.v1.api.widgetEvents.getWidgetEventRegistrationQuestions(this.eventId!);
      this.specificQuestionsFormDefinition = responseSpecific.items;
      const countries = await this.$service.v1.api.widgetEvents.getWidgetCountries();
      this.countries = countries.items;
      if (!this.model.contactAddress.countryId) {
        this.model.contactAddress.countryId = this.countries.find((x) => x.details === 'CH')?.id;
      }
    },
    async loadEventInformation() {
      this.isLoading = true;
      await this.$guards.waitForAppLoad();
      try {
        const widgetSearchClassRequestVM = new WidgetSearchClassRequestViewModel();
        widgetSearchClassRequestVM.toccoFilter = `(pk == long:"${this.eventId}")`;
        const page = new ApiPagingRequestViewModel();
        page.pageSize = 1;
        widgetSearchClassRequestVM.paging = page;
        const response = await this.$service.v1.api.widgetEvents.getWidgetEvents(widgetSearchClassRequestVM);
        [this.course] = response.items.filter((i) => i.id === this.eventId);
        await this.loadDynamicForms();
      } catch (e : any) {
        this.responseError = e;
        this.$log.error(e);
      }
      this.isLoading = false;
    },
  },
});
