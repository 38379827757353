import coreRoutes from '@glittr/frontend-core/src/routes';
import widgetEventSearch from '@glittr/frontend-core/src-modules/tocco/widgets/events/views/pages/widget-event-search.vue';
import Home from './views/pages/index.vue';

export default {
  mode: 'history',
  base: process.env!.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home,
    },
    ...coreRoutes,
    {
      path: '/bibliosuisse/il',
      name: 'dashboard',
      component: () => import('./views/pages/app/dashboard.vue'),
    },
    {
      path: '/_widget/events',
      name: 'widget-search',
      component: widgetEventSearch,
    },
    {
      path: '/_widget/events/register',
      alias: '/_widget/events/:eventid/register',
      name: 'event-register',
      component: () => import('./views/pages/widgets/events/widget-event-register.vue'),
    },
  ],
};
