// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2023 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import BaseModel from '@glittr/frontend-core/src/plugins/servicelayer/serviceTypes/baseModel';
import ContactAddressResourceViewModel from '../../../../viewModel/resource/ContactAddressResourceViewModel';
import ContactCorrespondenceAddressResourceViewModel from '../../../../viewModel/resource/ContactCorrespondenceAddressResourceViewModel';
import DynamicFormPayloadResourceViewModel from '../../../../viewModel/resource/DynamicFormPayloadResourceViewModel';
import DynamicFormFieldPayloadResourceViewModel from '../../../../viewModel/resource/DynamicFormFieldPayloadResourceViewModel';
import RequestSummaryResourceViewModel from '../../../../viewModel/resource/RequestSummaryResourceViewModel';
import RegisterEventRequestViewModel from '../../../../viewModel/resource/RegisterEventRequestViewModel';
import RegisterEventRequestDTO from '../../dto/resource/RegisterEventRequestDTO';

export default abstract class RegisterEventRequestModel extends BaseModel<RegisterEventRequestDTO> {
  /**
  * @type {int32}
  */
  get eventId() { return this.dto.eventId; }
  set eventId(value) { this.dto.eventId = value; }
  /**
  */
  get contactAddress() { return this.getObjectModel(() => this.dto.contactAddress, ContactAddressResourceViewModel)!; }
  set contactAddress(value) { this.setObjectModel(() => this.dto.contactAddress, value); }
  /**
  */
  get invoiceAddress() { return this.getObjectModel(() => this.dto.invoiceAddress, ContactCorrespondenceAddressResourceViewModel)!; }
  set invoiceAddress(value) { this.setObjectModel(() => this.dto.invoiceAddress, value); }
  /**
  */
  get correspondenceAddress() { return this.getObjectModel(() => this.dto.correspondenceAddress, ContactCorrespondenceAddressResourceViewModel)!; }
  set correspondenceAddress(value) { this.setObjectModel(() => this.dto.correspondenceAddress, value); }
  /**
  */
  get registrationAnswers() { return this.getObjectModel(() => this.dto.registrationAnswers, DynamicFormPayloadResourceViewModel)!; }
  set registrationAnswers(value) { this.setObjectModel(() => this.dto.registrationAnswers, value); }
  /**
  */
  get employmentPosition() { return this.dto.employmentPosition; }
  set employmentPosition(value) { this.dto.employmentPosition = value; }
  /**
  */
  get employmentArea() { return this.dto.employmentArea; }
  set employmentArea(value) { this.dto.employmentArea = value; }
  /**
  * @type {int32}
  */
  get employmentGradePercent() { return this.dto.employmentGradePercent; }
  set employmentGradePercent(value) { this.dto.employmentGradePercent = value; }
  /**
  * @type {date-time}
  */
  get employmentStartDate() { return this.dto.employmentStartDate; }
  set employmentStartDate(value) { this.dto.employmentStartDate = value; }
  /**
  */
  get comment() { return this.dto.comment; }
  set comment(value) { this.dto.comment = value; }
  /**
  */
  get marketingAnswers() { return this.getArrayModels(() => this.dto.marketingAnswers, DynamicFormFieldPayloadResourceViewModel)!; }
  set marketingAnswers(value) { this.setArrayModels(() => this.dto.marketingAnswers, value); }
  /**
  */
  get requestSummary() { return this.getObjectModel(() => this.dto.requestSummary, RequestSummaryResourceViewModel)!; }
  set requestSummary(value) { this.setObjectModel(() => this.dto.requestSummary, value); }
  /**
  */
  get acceptNewsletter() { return this.dto.acceptNewsletter; }
  set acceptNewsletter(value) { this.dto.acceptNewsletter = value; }
  /**
  */
  get acceptGdpr() { return this.dto.acceptGdpr; }
  set acceptGdpr(value) { this.dto.acceptGdpr = value; }
  /**
  */
  get languageIso2() { return this.dto.languageIso2; }
  set languageIso2(value) { this.dto.languageIso2 = value; }

  protected transformFromQuery(queries: Record<string, string>): void {
    const qs = {} as Record<string, string>;
    Object.keys(queries).forEach((key) => { qs[key.toLowerCase()] = queries[key]; });
    let value: any;
    value = Number.parseFloat(qs.eventid);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.eventId = value;
    this.dto.employmentPosition = qs.employmentposition;
    this.dto.employmentArea = qs.employmentarea;
    value = Number.parseFloat(qs.employmentgradepercent);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.employmentGradePercent = value;
    this.dto.employmentStartDate = qs.employmentstartdate;
    this.dto.comment = qs.comment;
    value = qs.acceptNewsletter === 'true' ? true : qs.acceptnewsletter;
    value = qs.acceptNewsletter === 'false' ? false : undefined;
    this.dto.acceptNewsletter = value;
    value = qs.acceptGdpr === 'true' ? true : qs.acceptgdpr;
    value = qs.acceptGdpr === 'false' ? false : undefined;
    this.dto.acceptGdpr = value;
    this.dto.languageIso2 = qs.languageiso2;
  }
}
