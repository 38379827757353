import Vue from 'vue';
import queryBuilder from '@glittr/frontend-core/src/plugins/validation/queryBuilder';
import { IJSONSchema } from '@glittr/frontend-core/src/plugins/validation/IJSONSchema';
import validationPatterns from '@glittr/frontend-core/src/plugins/validation/validationPatterns';
import _ from '@glittr/frontend-core/src/utils';
import RegisterEventRequestModel from '../../generated/types/model/resource/RegisterEventRequestModel';
import RequestSummaryItemResourceViewModel from './RequestSummaryItemResourceViewModel';
import RequestSummaryResourceViewModel from './RequestSummaryResourceViewModel';

export default class RegisterEventRequestViewModel extends RegisterEventRequestModel {
  protected transformToDTO(): void {
    this.dto.requestSummary = this.requestSummary.toDTO();
  }

  registrationAnswersSummary: RequestSummaryItemResourceViewModel[] = [];
  marketingAnswersSummary: RequestSummaryItemResourceViewModel[] = [];

  schemaInstitution: IJSONSchema = {
    ...queryBuilder.properties({
      companyName: { required: true },
      eMail: { required: true, pattern: validationPatterns.email },
      street: { required: true },
      postalCode: { required: true },
      city: { required: true },
      countryId: { required: true },
    }),
  };

  schema: IJSONSchema = {
    ...queryBuilder.properties({
      eventId: { required: true },
      contactAddress: {
        type: 'object',
        properties: {
          firstName: { required: true },
          lastName: { required: true },
          salutation: { required: true },
          eMail: { required: true, pattern: validationPatterns.email },
          street: { required: true },
          postalCode: { required: true },
          city: { required: true },
          dateOfBirth: { required: true },
          mobilePhoneNumber: { pattern: '[0-9+ ]' },
          phoneNumber: { pattern: '[0-9+ ]' },
          countryId: { required: true },
        },
      },
      acceptGdpr: {
        required: true,
        type: 'boolean',
        enum: [
          true,
        ],
      },
    }),
  };

  get salutationDisplay() { return Vue.$t(`widget.events.genderEnum.${this.contactAddress.salutation!}`); }

  get requestSummary() {
    return new RequestSummaryResourceViewModel().fromDTO({
      sections: [
        {
          title: Vue.$t('widget.events.sectionPersonalData'),
          items: [
            { label: Vue.$t('widget.events.gender'), displayValue: this.salutationDisplay },
            { label: Vue.$t('widget.events.firstName'), displayValue: this.contactAddress.firstName },
            { label: Vue.$t('widget.events.lastName'), displayValue: this.contactAddress.lastName },
            { label: Vue.$t('widget.events.street'), displayValue: this.contactAddress.street },
            { label: Vue.$t('widget.events.postalCode'), displayValue: this.contactAddress.postalCode },
            { label: Vue.$t('widget.events.city'), displayValue: this.contactAddress.city },
            { label: Vue.$t('widget.events.dateOfBirth'), displayValue: Vue.$format.localDate(this.contactAddress.dateOfBirth!) },
          ],
        },
        {
          title: Vue.$t('widget.events.sectionCommunication'),
          items: [
            { label: Vue.$t('widget.events.phonePrivate'), displayValue: this.contactAddress.phoneNumber },
            { label: Vue.$t('widget.events.mobilePrivate'), displayValue: this.contactAddress.mobilePhoneNumber },
            { label: Vue.$t('widget.events.eMail'), displayValue: this.contactAddress.eMail },
            { label: Vue.$t('widget.events.differentCorrespondenceAddress'), displayValue: this.correspondenceAddress.isDifferent ? Vue.$t('core.app.yes') : Vue.$t('core.app.no') },
            { label: Vue.$t('widget.events.differentInvoiceAddress'), displayValue: this.invoiceAddress.isDifferent ? Vue.$t('core.app.yes') : Vue.$t('core.app.no') },
          ],
        },
        {
          title: Vue.$t('widget.events.sectionCorrespondenceAddress'),
          items: [
            { label: Vue.$t('widget.events.companyName'), displayValue: this.correspondenceAddress.contactAddress.companyName },
            { label: Vue.$t('widget.events.street'), displayValue: this.correspondenceAddress.contactAddress.street },
            { label: Vue.$t('widget.events.postalCode'), displayValue: this.correspondenceAddress.contactAddress.postalCode },
            { label: Vue.$t('widget.events.city'), displayValue: this.correspondenceAddress.contactAddress.city },
            { label: Vue.$t('widget.events.phoneBusiness'), displayValue: this.correspondenceAddress.contactAddress.phoneNumber },
            { label: Vue.$t('widget.events.eMail'), displayValue: this.correspondenceAddress.contactAddress.eMail },
          ],
        },
        {
          title: Vue.$t('widget.events.sectionInvoiceAddress'),
          items: [
            { label: Vue.$t('widget.events.companyName'), displayValue: this.invoiceAddress.contactAddress.companyName },
            { label: Vue.$t('widget.events.street'), displayValue: this.invoiceAddress.contactAddress.street },
            { label: Vue.$t('widget.events.postalCode'), displayValue: this.invoiceAddress.contactAddress.postalCode },
            { label: Vue.$t('widget.events.city'), displayValue: this.invoiceAddress.contactAddress.city },
            { label: Vue.$t('widget.events.phoneBusiness'), displayValue: this.invoiceAddress.contactAddress.phoneNumber },
            { label: Vue.$t('widget.events.eMail'), displayValue: this.invoiceAddress.contactAddress.eMail },
          ],
        },
        {
          title: Vue.$t('widget.events.sectionSpecificQuestions'),
          items: this.registrationAnswersSummary.map((answer) => answer?.toDTO()),
        },
        {
          title: Vue.$t('widget.events.sectionAdditionalInformation'),
          items: [
            { label: Vue.$t('widget.events.comment'), displayValue: this.comment },
            ...this.marketingAnswersSummary.map((answer) => answer?.toDTO()),
            { label: Vue.$t('widget.events.acceptNewsletter'), displayValue: this.acceptNewsletter ? Vue.$t('core.app.yes') : Vue.$t('core.app.no') },
            { label: Vue.$t('widget.events.acceptGdpr'), displayValue: this.acceptGdpr ? Vue.$t('core.app.yes') : Vue.$t('core.app.no') },
          ],
        },
      ].map((section) => ({
        title: section.title,
        // Needs to be cast to any because build fails otherwise (and the dev build doesn't)
        items: (section.items as any).filter((item: any) => _.isSet(item.displayValue)),
      })).filter((section) => section.items.length > 0),
    });
  }
}
